.auth_layout_main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  flex: 1 1;
  align-self: center;
  padding: var(--geist-gap);
  flex-direction: column;
  max-width: 600px;
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  form {
    width: 100%;
    max-width: 360px;
    align-self: center;

    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      width: 100%;
    }
  }
}
