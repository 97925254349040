.auth_layout_bottom {
  height: 100px;

  background: var(--geist-background);
  border-top: 1px solid var(--accents-2);
  color: var(--geist-foreground);
  padding: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    margin: 0;
  }

  a {
  }
}
