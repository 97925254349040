.auth_layout {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  @media (max-width: 600px) {
    min-height: -webkit-fill-available;
  }
}

.header {
  min-height: var(--header-height);
  transition: box-shadow 0.1s ease 0s, background-color 0.1s ease 0s;
  box-shadow: var(--header-border-bottom);

  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: var(--geist-background);
  z-index: 101;

  .logo {
    font-size: 1.25rem;
    font-weight: 600;
  }
}
