.auth_confirmation {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  strong {
    text-align: center;
  }

  .text_wrapper {
    color: var(--accents-5);
    font-size: 0.875rem;
    text-align: center;
    line-height: 2rem;
    margin: 0;

    strong {
      color: var(--geist-foreground);
    }

    a {
      color: var(--geist-foreground);

      &:hover {
        background-image: linear-gradient(var(--geist-foreground), var(--geist-foreground));
        background-size: 100% 1px;
        background-position: 0 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
